import { Component, OnInit, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormArray, NgForm } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "../../auth/auth.service";
import { format } from "d3-format";
import { Router } from "@angular/router";
import { ElementRef } from "@angular/core";
import { DiscretePromptComponent } from "../discrete-prompt/discrete-prompt.component";
import { NumericPromptComponent } from "../numeric-prompt/numeric-prompt.component";
import { CognitiveComponent } from "../cognitive/cognitive.component";
import { FeatureComponent } from "../feature/feature.component";
import { SamplingRatesComponent } from "../../sampling-rates/sampling-rates.component";
import { WatchComponent } from "../watch/watch.component";
import { toInteger } from "@ng-bootstrap/ng-bootstrap/util/util";
import { Auth } from "aws-amplify";

@Component({
  selector: "app-edit-campaign",
  templateUrl: "./edit-campaign.component.html",
  styleUrls: ["./edit-campaign.component.scss"]
})

export class EditCampaignComponent implements OnInit{
  @ViewChild(DiscretePromptComponent, {static: false}) discreteprompt_child: DiscretePromptComponent;
  @ViewChild(NumericPromptComponent, {static: false}) numericprompt_child: NumericPromptComponent;
  @ViewChild(CognitiveComponent, {static: false}) cognitiveprompt_child: CognitiveComponent;
  @ViewChild(FeatureComponent, {static: false}) feature_child: FeatureComponent;
  @ViewChild(SamplingRatesComponent, {static: false}) samplingrates_child: SamplingRatesComponent;
  @ViewChild(WatchComponent, {static: false}) watch_child: WatchComponent;

  campaignid: any;
  discretePrompt: any;
  numericPrompt: any;
  cognitive: any;
  watch: any;
  samplingRate: any;
  feature: any;
  editCampaign: FormGroup;
  participants: any;
  campaigns: any;
  campaigninfo: any;
  starttime: NgbDateStruct;
  endtime: NgbDateStruct;
  meridianStart: boolean = true;
  meridianEnd: boolean = true;
  successMessage: string = "";
  success: boolean = false;
  isCampaignSelected: boolean = false;
  ROOT_URL_CAMPAIGN_INFO =
    "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/getcampaigninfo?campaignid=";
  ROOT_URL_CAMPAIGN =
    "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/getcampaign?campaignmanagerid=";
  readonly ROOT_URL_PARTICIPANT =
    "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/getparticipant";
  CONFIGURE_WATCH_POST =
    "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/assignpaticipant";
  UPDATE_CAMPAIGN_INFO =
    "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/updatecampaigninfo";
  constructor(private http: HttpClient, private authService: AuthService, private router: Router, private elementRef: ElementRef) {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  ngOnInit() {
    Auth.currentAuthenticatedUser().then(user => {
      this.http.get<any>(this.ROOT_URL_CAMPAIGN + user.username).subscribe(data => {
        //console.log(this.ROOT_URL_CAMPAIGN + user.email);
        //console.log(data);
        this.campaigns = data.campaigns;
      });
      this.http.get<any>(this.ROOT_URL_PARTICIPANT).subscribe(data => {
        //console.log(data.participant);
        // this.participants = data.participant;
        this.participants = data.participant;
      });
    });
    localStorage.removeItem("discrete_form");
    localStorage.removeItem("numeric_form");
    localStorage.removeItem("cognitive_form");
    localStorage.removeItem("watch_form");
    localStorage.removeItem("sampling_rates_form");
    localStorage.removeItem("feature_form");
  }

  updateTimeAndDayOfWeek(record) {
    //dayOfWeek logic
    //var dayOfWeek = [];
    //var time = {"time": []};
    for (var i = 0; i < record.length; ++i) {
      for (var j = 0; j < 7; ++j) {
        if (record[i].schedule[j].length > 0) {
          //dayOfWeek.push(j);
          //time logic
          var t = {};
          for (var k = 0; k < record[i].schedule[j].length; k++) {
            var min = parseInt(record[i].schedule[j][k]);
            var hours = Math.trunc(min/60);
            var minutes = min % 60;
            t = { "hour" : hours, "minute" : minutes };
          }
          //time = {"time": t}; 
          var temp_string = "Prompts_time_" + String(j);
          record[i][temp_string] = [{ "time" : t }];
        }
      }
    }
    
    //var dayOfWeekDict = {"dayOfWeek":dayOfWeek}
    //record.update(dayOfWeekDict)
    //record.update(time)
    //del record['schedule']
  }

  updateType(record) {
    for (var i = 0; i < record.length; ++i) {
      //console.log(record[i].type);
      if (record[i].type != null) {
        console.log(record[i].type);
        if (String(record[i].type) == "backward") {
          record[i]["backward"] = true;
          record[i]["forward"] = false;
        }
        else {
          record[i]["forward"] = true;
          record[i]["backward"] = false;
        }
      }
      if (!record[i].shape)
        record[i]["shape"] = null;
      if (!record[i].noOfTrails)
        record[i]["noOfTrails"] = null;
      if (!record[i].targetShape)
        record[i]["targetShape"] = null;
      if (!record[i].fromTime)
        record[i]["fromTime"] = null;
      if (!record[i].toTime)
        record[i]["toTime"] = null;
      if (!record[i].color)
        record[i]["color"] = null; 
    }
  }
  
  campaignIdChanged(e) {
    let id = e.target.value;
    if (id != null) {
      console.log("id = " + id);
      this.isCampaignSelected = true;
      this.campaignid = id;
      this.success = false;
      // if (id == String("testjson")) {
      //   var items = [{"question":"testjson","question2":"testjson","longUIquestion":"testjson","discrete_type":true,"values":[{"value":"2"}],"Prompts_time_0":[{"time":{"hour":3,"minute":10}}],"Prompts_time_1":[{"time":{"hour":3,"minute":10}}],"Prompts_time_2":[{"time":{"hour":3,"minute":10}}],"Prompts_time_3":[{"time":{"hour":3,"minute":10}}],"Prompts_time_4":[{"time":{"hour":3,"minute":10}}],"Prompts_time_5":[{"time":{"hour":3,"minute":10}}],"Prompts_time_6":[{"time":{"hour":3,"minute":10}}]}]
      //   localStorage.setItem("discrete_form", JSON.stringify(items));
      // }
      // else {
      //   var items = [{"question":"test2","question2":"test2","longUIquestion":"test2","discrete_type":true,"values":[{"value":"5"}],"Prompts_time_0":[{"time":{"hour":3,"minute":10}}],"Prompts_time_1":[{"time":{"hour":3,"minute":10}}],"Prompts_time_2":[{"time":{"hour":3,"minute":10}}],"Prompts_time_3":[{"time":{"hour":3,"minute":10}}],"Prompts_time_4":[{"time":{"hour":3,"minute":10}}],"Prompts_time_5":[{"time":{"hour":3,"minute":10}}],"Prompts_time_6":[{"time":{"hour":3,"minute":10}}]}]
      //   localStorage.setItem("discrete_form", JSON.stringify(items));
      // }
      // this.http.get<any>(this.ROOT_URL_CAMPAIGN_INFO + String(this.campaignid)).subscribe(data => {
      //   console.log(data.campaigninfo);
      //   this.campaigninfo = data.campaigninfo;
      // });
      this.http.get<any>(this.ROOT_URL_CAMPAIGN_INFO + String(this.campaignid)).subscribe(data => {
      //console.log(data.campaigninfo);
      this.campaigninfo = data.campaigninfo;
      console.log(this.campaigninfo);
      //console.log(JSON.stringify(this.campaigninfo));
      //console.log(this.campaigninfo.DiscreetPrompts);

      localStorage.removeItem("discrete_form");
      localStorage.removeItem("numeric_form");
      localStorage.removeItem("cognitive_form");
      localStorage.removeItem("watch_form");
      localStorage.removeItem("sampling_rates_form");
      localStorage.removeItem("feature_form");

      if (this.campaigninfo) {
        //console.log("Inside IF condition");
        if (this.campaigninfo.DiscreetPrompts.length != 0) {
          var record = this.campaigninfo.DiscreetPrompts;
          //console.log(record);
          this.updateTimeAndDayOfWeek(record);
          //console.log(record);
          localStorage.setItem("discrete_form", JSON.stringify(record));
        }
        if (this.campaigninfo.NumericPrompts.length != 0) {
          var record = this.campaigninfo.NumericPrompts;
          //console.log(record);
          this.updateTimeAndDayOfWeek(record);
          //console.log(record);
          localStorage.setItem("numeric_form", JSON.stringify(record));
        }
          
        if (this.campaigninfo.CognitivePrompts.length != 0) {
          var record = this.campaigninfo.CognitivePrompts;
          //console.log(record);
          this.updateTimeAndDayOfWeek(record);
          this.updateType(record);
          console.log(record);
          localStorage.setItem("cognitive_form", JSON.stringify(record));
        }
          
        //if (this.campaigninfo.Features.length != 0)

        var features_details = [
          {
            mvm: this.campaigninfo.Features[0].calculate,
            svdm: this.campaigninfo.Features[1].calculate,
            mangle: this.campaigninfo.Features[2].calculate,
            sdangle: this.campaigninfo.Features[3].calculate, 
            df: this.campaigninfo.Features[4].calculate, 
            fpdf: this.campaigninfo.Features[5].calculate, 
            p625: this.campaigninfo.Features[6].calculate,
            gps: this.campaigninfo.Features[7].calculate, 
            battery: this.campaigninfo.Features[8].calculate
          }
        ];
        
        localStorage.setItem("feature_form", JSON.stringify(features_details));
        
        var sampling_rates = [
          {
            location_rate: this.campaigninfo.WatchConfigurationParameters.location_rate,
            export_rate: this.campaigninfo.WatchConfigurationParameters.export_rate,
            battery_rate: this.campaigninfo.WatchConfigurationParameters.battery_rate,
            SAMPLING_RATE: this.campaigninfo.WatchConfigurationParameters.SAMPLING_RATE,
            accel_rate: this.campaigninfo.WatchConfigurationParameters.accel_rate,
            heartrate_rate: this.campaigninfo.WatchConfigurationParameters.heartrate_rate,
            VARIABLE_CONSTRUCTION_RATE: this.campaigninfo.WatchConfigurationParameters.VARIABLE_CONSTRUCTION_RATE
          }
        ];
        var watch_details = [
          {
            SEND_TO_SERVER: this.campaigninfo.WatchConfigurationParameters.SEND_TO_SERVER,
            RECEIVE_CONFIG_FROM_SERVER: this.campaigninfo.WatchConfigurationParameters.RECEIVE_CONFIG_FROM_SERVER,
            RAW_MODE: this.campaigninfo.WatchConfigurationParameters.RAW_MODE,
            location_active: this.campaigninfo.WatchConfigurationParameters.location_active,
            battery_active: this.campaigninfo.WatchConfigurationParameters.battery_active,
            SAVE_LOCALLY: this.campaigninfo.WatchConfigurationParameters.SAVE_LOCALLY,
            gyro_active: this.campaigninfo.WatchConfigurationParameters.gyro_active,
            heartrate_active: this.campaigninfo.WatchConfigurationParameters.heartrate_active,
            accel_active: this.campaigninfo.WatchConfigurationParameters.accel_active,
            pressure_active: this.campaigninfo.WatchConfigurationParameters.pressure_active
          }
        ];
        localStorage.setItem("sampling_rates_form", JSON.stringify(sampling_rates));
        localStorage.setItem("watch_form", JSON.stringify(watch_details));
      }

      if (this.discreteprompt_child)
        this.discreteprompt_child.ngOnInit();
      if (this.numericprompt_child)  
        this.numericprompt_child.ngOnInit();
      if (this.cognitiveprompt_child)
        this.cognitiveprompt_child.ngOnInit();
      if (this.feature_child)
        this.feature_child.ngOnInit();
      if (this.samplingrates_child)
        this.samplingrates_child.ngOnInit();
      if (this.watch_child)
        this.watch_child.ngOnInit();
      //this.elementRef.nativeElement.remove();
      //this.ngOnInit();
      //this.router.navigate([this.router.url]);
      //this.reloadComponent();
      });
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }

  onSubmit() {
    Auth.currentAuthenticatedUser().then(user => {
      const data = {
        campaignManager: user.username,
        campaignid: this.campaignid,
        DiscreetPrompts: this.discretePrompt,
        NumericPrompts: this.numericPrompt,
        CognitivePrompts: this.cognitive,
        WatchConfigurationParameters: this.watch,
        SamplingRateParameters: this.samplingRate,
        Features: this.feature,
      };
      const result = { campaignid: this.campaignid, item: data };
      console.log(result);
      //console.log(JSON.stringify(data));
      this.http.post(this.UPDATE_CAMPAIGN_INFO, result).subscribe((data) => {
        console.log(data, "subscribe");
        this.success = true;
      });
    });
  }

  receiveDiscretePrompt($event) {
    this.discretePrompt = $event;
  }

  receiveNumericPrompt($event) {
    this.numericPrompt = $event;
  }

  receiveCognitive($event) {
    this.cognitive = $event;
  }

  receiveWatch($event) {
    this.watch = $event;
  }
  receiveSamplingRate($event) {
    this.samplingRate = $event;
  }
  receiveFeature($event) {
    this.feature = $event;
  }
}
