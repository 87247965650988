import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { AuthService } from "../../auth/auth.service";
import { Subscription } from "rxjs";
import { Auth } from "aws-amplify";
import { Hub } from 'aws-amplify';
import { delay } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatSort } from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { ViewChild } from "@angular/core";
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from "@angular/cdk/layout";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { WatchStatusComponent } from "../watch-status/watch-status.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {MatGridListModule} from '@angular/material/grid-list';
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],


})
export class NavbarComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav, {static: true})
  sidenav!: MatSidenav;

  isAuthenticated = false;
  private userSub: Subscription;
  user: any;
  
  constructor(private authService: AuthService, private router: Router, private ngZone: NgZone, private observer: BreakpointObserver, private modalService: NgbModal) {}
 
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  ngOnInit() {
    /*this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });*/
    Auth.currentAuthenticatedUser().then((user) => {
      this.isAuthenticated = !!user;
      //console.log(!user, !!user);
      this.user = user;
      console.log(user);
    });
    
    Hub.listen('auth', async (data) => {
      console.log(data.payload.event);
      switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in');
            this.user = await Auth.currentAuthenticatedUser();
            this.isAuthenticated = true;
            //console.log(user);
            this.ngZone.run(()=> this.router.navigate([""]));
            break;
      }
    });
  }

  onLogout() {
    this.isAuthenticated = false;
    this.authService.logout();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
