import {
  Component,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Input
} from "@angular/core";
import { DataService } from "../data.service";
import * as d3 from "d3";
import { Subscription } from "rxjs";
import { ViewChild } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: "[graph]",
  templateUrl: "./graphs.component.html",
  styleUrls: ["./graphs.component.scss"]
})
export class GraphsComponent {
  showGraph: boolean;
  showMap: boolean;
  watchData: any;
  subscription: Subscription;
  @ViewChild('graph' , {static: false}) mapElement: any;
  map: any;
  constructor(private data: DataService) {
    //subscribe to data changes
    this.subscription = this.data.getData().subscribe(data => {
      if (data) {
        console.log("data 1: " + data[0]);
        console.log(data[1]);
        console.log("Inside graph constructor");
        document.getElementById("outerDiv").innerHTML = '';
        //document.getElementById("outerDiv").removeChild(document.getElementById("graph"));
        if (data[1] == "gps") {
          //x.style = "width:100%;height:400px";
          var div = document.createElement('div');
          div.setAttribute("id", "map");
          document.getElementById("outerDiv").appendChild(div);
          document.getElementById("map").style.width = "100%";
          document.getElementById("map").style.height = "450px";
          this.initMap(data[0], this.map);
        }
        else {
          var div = document.createElement('div');
          div.setAttribute("id", "graph");
          document.getElementById("outerDiv").appendChild(div);
          this.drawGraph(data[0]);
        }
      }
      else {
        console.log("no data");
      }
    });
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initMap(data, mapId) {
    console.log("in init map");
    var blueIcon = L.icon({
        iconUrl: '../../../assets/images/marker-icon-2x.png',
        iconSize:     [25, 55], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });
    const tiles = L.tileLayer(
          "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          {
            maxZoom: 19,
            attribution:
              '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          }
        );
      mapId = L.map("map", {
        center: [29.6431, -82.3613],
        zoom: 14,
        preferCanvas: true,
      });
      var centerMark = [];
      data.values.forEach(function(d) {
        var temp = d.value.split(',');
        if (temp[0].trim()!="null" && temp[1].trim()!="null") {
          L.marker([+temp[0].trim(), +temp[1].trim()], {icon: blueIcon}).addTo(mapId);
          if(centerMark.length == 0) {
            centerMark.push(+temp[0].trim());
            centerMark.push(+temp[1].trim());
          }
        }
      });
      mapId.panTo(new L.LatLng(centerMark[0], centerMark[1]));

      tiles.addTo(mapId);
    /*var bounds = new google.maps.LatLngBounds();
    const map = new google.maps.Map(this.mapElement.nativeElement, {
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    var flightPlanCoordinates = [];
    var latlngData = {
      lat: 0,
      lng:0
    };
    data.values.forEach(function(d) {
      var temp = d.value.split(',');
      if (temp[0].trim()!="null" && temp[1].trim()!="null") {
        latlngData['lat'] = +temp[0].trim();
        latlngData['lng'] = +temp[1].trim();
        flightPlanCoordinates.push(latlngData);
        bounds.extend(new google.maps.LatLng(latlngData['lat'], latlngData['lng']));
        var marker = new google.maps.Marker({
          map: map,
          draggable: true,
          animation: google.maps.Animation.DROP,
          position: {lat: latlngData['lat'], lng: latlngData['lng']}
        });
      }
    });
    console.log(flightPlanCoordinates);
    const flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 3
    });
    map.fitBounds(bounds);
    map.panToBounds(bounds)
    flightPath.setMap(map);
  */
  }
  drawGraph(data) {
    // d3.select("#barChart").select("svg").remove();
    // if (d3.select('#graph').select('svg')) {
    // 	console.log('graph exists');
    // } else {
    // 	console.log('graph does not exist');
    // }
    d3.select("#graph")
      .select("svg")
      .remove();
    var margin = { top: 20, right: 20, bottom: 100, left: 50 },
      width = 960 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;
    function formatTimeF(timestamp: string): Object {
      var parseTime = d3.timeParse("%m/%d/%Y, %H:%M:%S");
      return parseTime(timestamp);
    }
    data.values.forEach(function(d) {
      if (d.value != "None") {
        d.collectedtimestamp = formatTimeF(d.collectedtimestamp);
        d.value = +d.value;
      }
    });

    var x = d3.scaleTime().range([0, width]);
    var y = d3.scaleLinear().range([height, 0]);
    var valueline = d3
      .line()
      .x(function(d: any) {
        formatTimeF(d.collectedtimestamp);
        return x(d.collectedtimestamp);
      })
      .y(function(d: any) {
        if (d.value != undefined) {
          return y(d.value);
        }
      });
    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip-scatter")
      .style("opacity", 0);
    var svg = d3
      .select("#graph")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    x.domain(
      <[Date, Date]>d3.extent(data.values, function(d: any) {
        return d.collectedtimestamp;
      })
    );
    y.domain(<[any, any]>[
      0,
      d3.max(data.values, function(d: any) {
        return +d.value;
      })
    ]);

    svg
      .append("path")
      .data([data.values])
      .attr("class", "line")
      .attr("d", valueline);

    // Add the scatterplot
    svg
      .selectAll("dot")
      .data(data.values)
      .enter()
      .append("circle")
      .attr("r", 5)
      .attr("cx", function(d: any) {
        return x(d.collectedtimestamp);
      })
      .attr("cy", function(d: any) {
        return y(d.value);
      })
      .style("fill", "white")
      .style("stroke", "#75B9BE")
      .style("stroke-width", "3px")
      .on("mouseover", function(d: any) {
        d3.select(this)
          .transition()
          .duration(50)
          .attr("opacity", ".85");
        tooltip
          .transition()
          .duration(50)
          .style("opacity", 1)
          .style("left", d3.event.pageX + 10 + "px")
          .style("top", d3.event.pageY - 15 + "px");
        var format = d3.timeFormat("%m/%d/%Y, %H:%M:%S");
        tooltip.html(
          "<span>Date: " +
            format(d.collectedtimestamp) +
            "</span><br><span> Value: " +
            d.value +
            "</span>"
        );
      })
      .on("mouseout", function(d, i) {
        d3.select(this)
          .transition()
          .duration(50)
          .attr("opacity", "1");

        tooltip
          .transition()
          .duration(50)
          .style("opacity", 0);
      });

    // Add the X Axis
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Add the Y Axis
    svg.append("g").call(d3.axisLeft(y));
  }
}
