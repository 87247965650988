import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit } from "@angular/core";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { DataService } from "../data.service";
import { AuthService } from "../../auth/auth.service";
import { User } from "../../auth/user.model";
import { BehaviorSubject, Subscription } from "rxjs";
import { Auth } from "aws-amplify";
import { ViewChild } from "@angular/core";
import { MatSort } from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatRow, MatTableDataSource} from '@angular/material/table';
import { SelectionModel } from "@angular/cdk/collections";
import { GraphsComponent } from '../graphs/graphs.component';

declare var require: any;

export interface WatchData {
  participantid: number;
  campaignid: string;
  watchid: string;
  starttime: string;
  endtime: string;
  NumericPrompts: string;
  Features: string;
  DiscreetPrompts: string;
}

const  watchTable : WatchData[] = [];

const allowMultiSelect = false;

@Component({
  selector: "app-watch-status",
  templateUrl: "./watch-status.component.html",
  styleUrls: ["./watch-status.component.scss"]
})
export class WatchStatusComponent implements OnInit {
  click: boolean;
  @Input() name;
  category: string;
  subscription: Subscription;
  questionIdList: any;
  npQuestions: any;
  dpQuestions: any;
  featureQuestions: any;
  dict: any = {};
  discreteValues: any;
  questionId: any;
  eventValue: any;
  selectedRow: any;
  dataSource = new MatTableDataSource<WatchData>(watchTable);
  displayedColumns: string[] = ['select','participantid', 'campaignid', 'watchid', 'starttime', 'endtime', 'download'];
  static initialSelection = [];
  static allowMultiSelect = true;
  selection = new SelectionModel<WatchData>(allowMultiSelect, WatchStatusComponent.initialSelection);
 
  watchstatus: any;
  ROOT_URL_CAMPAIGN =
    "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/watchstatus?campaignmanagerid=";
  public user: BehaviorSubject<any>;
  public showSpinner: boolean = false;
  @Output() questionIdEvent = new EventEmitter<any>();
  @Output() testOut = new EventEmitter<any>();
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private data: DataService,
    private authService: AuthService,
  ) {
    this.subscription = this.data.getData().subscribe(data => {
      if (data) {
        this.discreteValues = data[0].values;
      }
    });
  }

  @ViewChild(MatPaginator, {static: false}) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
}

  @ViewChild(MatSort, {static: false}) sort: MatSort
  


  ngOnInit() {
    Auth.currentAuthenticatedUser().then(user => {
      if (!localStorage.getItem('foo')) { 
        localStorage.setItem('foo', 'no reload') 
        location.reload() 
      } else {
        localStorage.removeItem('foo') 
      }
      this.showSpinner = true;
      console.log(this.ROOT_URL_CAMPAIGN + user.username);
      this.http.get<any>(this.ROOT_URL_CAMPAIGN + user.username).subscribe(data => {
        this.showSpinner = false;
        this.watchstatus = data.body;
        this.dataSource = new MatTableDataSource(data.body);
        this.dataSource.sort = this.sort;
        console.log(data);
      });
    });
    
  }

  

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

   getQuestionList(questions) {
    let questionList = [];
    for (var i = 0; i < questions.length; i++) {
      questionList.push(questions[i].question);
      this.dict[questions[i].question] = questions[i].questionid;
    }
    return questionList;
  }

  selectRow($event: any, dsource: any): void {
    if($event.checked) {
      this.selectedRow = dsource;
    }
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public getValues(category_, features_, dp, np) {
    let numericPromptQuestions = [];

    let discretePromptQuestions = [];

    let featureQuestions = [];
    console.log(this.dict);
    console.log("Category: " + category_);
    if (category_ == "Numeric Prompts") {
      console.log("np");
      numericPromptQuestions = this.getQuestionList(np);
      this.questionIdList = numericPromptQuestions;
    } else if (category_ == "Discrete Prompts") {
      console.log("dp");
      discretePromptQuestions = this.getQuestionList(dp);
      this.questionIdList = discretePromptQuestions;
    } else if (category_ == "Features") {
      featureQuestions = this.getQuestionList(features_);
      this.questionIdList = featureQuestions;
    }
    
    console.log("accessed");
    this.npQuestions = np;
    this.dpQuestions = dp;
    this.featureQuestions = features_;
  }

  public refreshDiv(graphID) {
    document.getElementById(graphID).innerHTML = "";
  }

  public dataInput(watchId_, starttime_, endtime_, event) {
    if (event.target.checked) {
      //this.showSpinner = true;
      this.questionId = this.dict[event.target.value];
      this.eventValue = event.target.value;
      console.log("target value: " + event.target.value);
    }
    
      //this.questionId = value[0];
      //this.eventValue = value[1];
      console.log(
        "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/apifordata?watchid=" +
          watchId_ +
          "%2B" +
          this.questionId +
          "%2B" +
          starttime_ +
          "%2B" +
          endtime_
      );
      this.http
      .get<any>(
        "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/apifordata?watchid=" +
          watchId_ +
          "%2B" +
          this.questionId +
          "%2B" +
          starttime_ +
          "%2B" +
          endtime_
      )
      .subscribe((d) => {
        console.log("after starttime and endtime", d);
        this.showSpinner = false;
        this.data.sendData(d, this.eventValue);
        

      });
  }

  data1 = {
    statusCode: 200,
    body: {
      Items: [
        {
          participantid: "123",
          campaignid: "455",
          watchid: "26EB",
          campaign: {
            NumericPrompts: [
              {
                question: "Protein",
                questionid: 1,
                longUIquestion: "Whats your protein intake"
              },
              {
                question: "fat",
                questionid: 2,
                longUIquestion: "Whats your fat intake"
              },
              {
                question: "calcium",
                questionid: 3,
                longUIquestion: "Whats your calcium intake"
              }
            ],
            DiscreetPrompts: [
              {
                question: "Symptions",
                questionid: 4,
                longUIquestion: "The symptoms that patient experienced"
              },
              {
                question: "Mobility",
                questionid: 5,
                longUIquestion: "The extent of mobility of patient"
              }
            ],
            Features: [
              {
                question: "mvm",
                questionid: 91,
                calculate: true
              },
              {
                question: "svdm",
                questionid: 92,
                calculate: true
              },
              {
                question: "mangle",
                questionid: 93,
                calculate: true
              },
              {
                question: "sdangle",
                questionid: 94,
                calculate: true
              },
              {
                question: "df",
                questionid: 95,
                calculate: true
              },
              {
                question: "fpdf",
                questionid: 96,
                calculate: true
              },
              {
                question: "p625",
                questionid: 97,
                calculate: true
              }
            ]
          }
        }
      ]
    }
  };
}
