import { Component, Output, EventEmitter, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";

@Component({
  selector: "app-cognitive",
  templateUrl: "./cognitive.component.html"
})
export class CognitiveComponent implements OnInit {
  @Input() disableInput: boolean = false;
  cognitiveForm: FormGroup;
  items: any = [];
  constructor(private fb: FormBuilder) {}
  ngOnInit() {
    var items = localStorage.getItem("cognitive_form");
    if (items) {
      this.items = JSON.parse(items);
      // this.populateDiscrete(this.items);
    }
    this.cognitiveForm = this.fb.group({
      cognitives: this.fb.array([])
    });
    const cognitive = this.fb.group({
      forward: [true],
      backward: [true],
      shapes: [""],
      noOfTrails: [""],
      targetShape: [""],
      fromTime: [""],
      toTime:[""],
      color: [""],
      Prompts_time_0: this.fb.array([this.timeArr()]),
      Prompts_time_1: this.fb.array([this.timeArr()]),
      Prompts_time_2: this.fb.array([this.timeArr()]),
      Prompts_time_3: this.fb.array([this.timeArr()]),
      Prompts_time_4: this.fb.array([this.timeArr()]),
      Prompts_time_5: this.fb.array([this.timeArr()]),
      Prompts_time_6: this.fb.array([this.timeArr()])
    });
    if (items) {
      for (var i = 0; i < this.items.length; i++) {
      const cognitiveItem = this.fb.group({
      forward: [this.items[i].forward],
    backward: [this.items[i].backward],
          shapes: [this.items[i].shapes],
          noOfTrails: [this.items[i].noOfTrails],
          targetShape: [this.items[i].targetShape],
	  fromTime: [this.items[i].fromTime],
	  toTime: [this.items[i].toTime],
          color: [this.items[i].color],
        Prompts_time_0: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_0)),
        Prompts_time_1: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_1)),
        Prompts_time_2: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_2)),
        Prompts_time_3: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_3)),
        Prompts_time_4: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_4)),
        Prompts_time_5: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_5)),
        Prompts_time_6: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_6))
        });
        // discretePromptItem.controls.values.setValue(this.fb.array(temp));
        this.cognitiveForms.push(cognitiveItem);
      }
    } else {
      this.cognitiveForms.push(cognitive);
    }
    if (this.disableInput == true) {
      this.cognitiveForm.disable();
    }
    this.submit();
  }
  get cognitiveForms() {
    return this.cognitiveForm.get("cognitives") as FormArray;
  }
  timeArr(): FormGroup {
        return this.fb.group({
          time: [{ hour: 3, minute: 10 }],
        });
    }
  constructTimeArray(promptTimes) {
    var timeTempArr = [];
    if (promptTimes) {
      for(var i=0;i<promptTimes.length;i++) {
        var timeJson = {hour: promptTimes[i]['time']['hour'], minute: promptTimes[i]['time']['minute']};
        timeTempArr.push(this.fb.group({
          time: timeJson,
        }));
      }
    }
    return timeTempArr;
  }
    addValue(i, day) {
      var day_times_array = "Prompts_time_"+day;
      const timeArray = this.cognitiveForm
        .get("cognitives")
        ["controls"][i].get(day_times_array) as FormArray;
      timeArray.push(this.timeArr());
    }
    deleteValue(i, iy, day) {
      var day_times_array = "Prompts_time_"+day;
      const timeArray = this.cognitiveForm
        .get("cognitives")
        ["controls"][i].get(day_times_array) as FormArray;
      timeArray.removeAt(iy);
    }
    addTimeValue(i, day) {
              var day_times_array = "Prompts_time_"+day;
              const timeArray = this.cognitiveForm
                .get("cognitives")
                ["controls"][i].get(day_times_array) as FormArray;
              timeArray.push(this.timeArr());
            }
            deleteTimeValue(i, iy, day) {
              var day_times_array = "Prompts_time_"+day;
              const timeArray = this.cognitiveForm
                .get("cognitives")
                ["controls"][i].get(day_times_array) as FormArray;
              timeArray.removeAt(iy);
            }
  addCognitive() {
    const cognitive = this.fb.group({
    forward: [true],
    backward: [true],
    shapes: [""],
      noOfTrails: [""],
      targetShape: [""],
      fromTime: [""],
      toTime: [""],
      color: [""],
      Prompts_time_0: this.fb.array([this.timeArr()]),
      Prompts_time_1: this.fb.array([this.timeArr()]),
      Prompts_time_2: this.fb.array([this.timeArr()]),
      Prompts_time_3: this.fb.array([this.timeArr()]),
      Prompts_time_4: this.fb.array([this.timeArr()]),
      Prompts_time_5: this.fb.array([this.timeArr()]),
      Prompts_time_6: this.fb.array([this.timeArr()])
    });
    this.cognitiveForms.push(cognitive);
  }
  deleteCognitive(i) {
    this.cognitiveForms.removeAt(i);
  }

  @Output() messageEvent = new EventEmitter<any>();

  submit() {
    localStorage.setItem(
      "cognitive_form",
      JSON.stringify(this.cognitiveForms.value)
    );
    this.messageEvent.emit(this.cognitiveForms.value);
    //console.log(this.cognitiveForms.value);
  }
}
