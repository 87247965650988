import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Hub } from "aws-amplify";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"]
})
export class AuthComponent implements OnInit {
  isLogin = true;
  error: string = null;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {}

  // onSwitchMode() {
  //   this.isLogin = !this.isLogin;
  // }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const email = form.value.username;
    const password = form.value.password;
    if (this.isLogin) {
      //console.log("In auth.component before login");
      try {
        this.authService.login(email, password).catch((error) => {
          this.error = error.message;
        });
      }
      catch (error) {
        console.log('error signing in', error);
      }

      Hub.listen('auth', (data) => {
        console.log(data.payload.event);
        switch (data.payload.event) {
          case 'signIn':
              console.log('user signed in');
              //console.log("before router navigate");
              //console.log("router URL = " + this.router.url);
              this.router.navigate([""]);
              break;
          case 'signUp':
              console.log('user signed up');
              break;
          case 'signOut':
              console.log('user signed out');
              break;
          case 'signIn_failure':
              console.log('user sign in failed');
              // this.error = "Incorrect username or password";
              // this.router.navigate(["/auth"]);
              break;
          case 'configured':
              console.log('the Auth module is configured');
        }
      });

    } else {
      this.authService.signup(email, password).subscribe(
        resData => {
          this.router.navigate([""]);
        },
        errorMessage => {
          this.error = errorMessage;
        }
      );
    }
    form.reset();
  }
}
