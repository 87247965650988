import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { DataService } from "../data.service";
import { Subscription } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ElementFinder } from "protractor";
declare var require: any
@Component({
  selector: "ngbd-modal-content",
  templateUrl: "./modal-content.component.html",
  styleUrls: ["./modal-content.component.scss"]
})
export class NgbdModalContent {
  @Input() name;
  questionId: any;
  category: string;
  subscription: Subscription;
  showSpinner: boolean = false;
  questionIdList: any;
  npQuestions: any;
  dpQuestions: any;
  featureQuestions: any;
  dict: any = {};
  discreteValues: any;

  @Output() questionIdEvent = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private data: DataService,
    private http: HttpClient
  ) {
    this.subscription = this.data.getData().subscribe(data => {
      if (data) {
        this.discreteValues = data[0].values;
      }
    });
  }
  ngOnInit() {
    let numericPromptQuestions = [];

    let discretePromptQuestions = [];

    let featureQuestions = [];
    console.log(this.dict);
    if (this.category == "Numeric Prompts") {
      numericPromptQuestions = this.getQuestionList(this.npQuestions);
    } else if (this.category == "Discrete Prompts") {
      discretePromptQuestions = this.getQuestionList(this.dpQuestions);
    } else {
      featureQuestions = this.getQuestionList(this.featureQuestions);
    }

    this.questionIdList =
      this.category == "Numeric Prompts"
        ? numericPromptQuestions
        : this.category == "Discrete Prompts"
        ? discretePromptQuestions
        : featureQuestions;
    if (this.category == undefined) {
      this.category = "Features";
    }
  }
  getQuestionList(questions) {
    let questionList = [];
    for (var i = 0; i < questions.length; i++) {
      questionList.push(questions[i].question);
      this.dict[questions[i].question] = questions[i].questionid;
    }
    return questionList;
  }
  toggleEvent(event) {
    if (event.target.checked) {
      this.showSpinner = true;
      this.questionId = this.dict[event.target.value];
      console.log(event.target.value);
      this.questionIdEvent.emit([this.questionId, event.target.value]);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

@Component({
  selector: "ngbd-modal-component",
  templateUrl: "./modal.component.html",
})

export class NgbdModalComponent {
  @Input("watchId") watchId: string;
  @Input("starttime") starttime: string;
  @Input("endtime") endtime: string;
  @Input("category") category: string;
  @Input("npQuestions") np: string;
  @Input("dpQuestions") dp: string;
  @Input("featureQuestions") featureQuestions: string;
  questionId: any;
  subscription: Subscription;
  modalRef: any;
  eventValue: any;
  constructor(
    private modalService: NgbModal,
    private data: DataService,
    private http: HttpClient
  ) {}

  open() {
    this.modalRef = this.modalService.open(NgbdModalContent);

    this.modalRef.componentInstance.category = this.category;
    this.modalRef.componentInstance.npQuestions = this.np;
    this.modalRef.componentInstance.dpQuestions = this.dp;

    this.modalRef.componentInstance.featureQuestions = this.featureQuestions;
    this.modalRef.componentInstance.questionIdEvent.subscribe((value) => {
      console.log(value);
      this.questionId = value[0];
      this.eventValue = value[1];
      this.numericPromptModal(
        this.watchId,
        this.questionId,
        this.starttime,
        this.endtime,
        this.eventValue
      );
    });
  }

  public numericPromptModal(watchid, questionid, starttime, endtime, eventValue) {

    console.log(
      "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/apifordata?watchid=" +
        watchid +
        "%2B" +
        questionid +
        "%2B" +
        starttime +
        "%2B" +
        endtime
    );
    this.http
      .get<any>(
        "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/apifordata?watchid=" +
          watchid +
          "%2B" +
          questionid +
          "%2B" +
          starttime +
          "%2B" +
          endtime
      )
      .subscribe((d) => {
        console.log("after starttime and endtime", d);
        this.modalRef.componentInstance.showSpinner = false;

        this.data.sendData(d, eventValue);
      });
  }
}

@Component({
  selector: "ngbd-modal-download-component",
  templateUrl: "./modal.download.component.html",
})

export class NgbdModalDownloadComponent {
  @Input("watchId") watchId: string;
  @Input("starttime") starttime: string;
  @Input("endtime") endtime: string;
  @Input("category") category: string;
  @Input("npQuestions") npQuestions: string;
  @Input("dpQuestions") dpQuestions: string;
  @Input("featureQuestions") featureQuestions: string;
  questionId: any;
  subscription: Subscription;
  downloadDisable: boolean = false;
  modalRef: any;
  dict: any = {};
  constructor(
    private modalService: NgbModal,
    private data: DataService,
    private http: HttpClient
  ) {}

  download() {
    this.downloadDisable = true;
    let npQuestionsList = [];
    let featureQuestionsList = [];
    let dpQuestionsList = [];
    npQuestionsList = this.getQuestionList(this.npQuestions);
    featureQuestionsList = this.getQuestionList(this.featureQuestions);
    dpQuestionsList = this.getQuestionList(this.dpQuestions);
    console.log(this.dict);
    this.http
          .get<any>(
            "https://dhfytq5t67.execute-api.us-east-2.amazonaws.com/campaign/downloaddata?watchid=" +
              this.watchId +
              "%2B" +
              "93" +
              "%2B" +
              this.starttime +
              "%2B" +
              this.endtime
          )
          .subscribe((url) => {
            console.log("presigned url to download data: ", url['body']);
            window.open(url['body']);
            this.downloadDisable = false;
          });
  }

  getQuestionList(questions) {
      let questionList = [];
      for (var i = 0; i < questions.length; i++) {
        questionList.push(questions[i].question);
        this.dict[questions[i].question] = questions[i].questionid;
      }
      return questionList;
    }
}
