import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  ReactiveFormsModule,
  FormControlDirective,
  FormGroupDirective,
  FormsModule
} from "@angular/forms";
import {MatGridListModule} from '@angular/material/grid-list';
import { NgbModule, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DiscretePromptComponent } from "./blocks/discrete-prompt/discrete-prompt.component";
import { NumericPromptComponent } from "./blocks/numeric-prompt/numeric-prompt.component";
import { CognitiveComponent } from "./blocks/cognitive/cognitive.component";
import { WatchComponent } from "./blocks/watch/watch.component";
import { NavbarComponent } from "./blocks/partials/navbar.component";
import { HttpClientModule } from "@angular/common/http";
import { ParticipantComponent } from "./blocks/participant/participant.component";
import { FeatureComponent } from "./blocks/feature/feature.component";
import { ConfigureWatchComponent } from "./blocks/configure-watch/configure-watch.component";
import { EditCampaignComponent } from "./blocks/edit-campaign/edit-campaign.component"
import { WatchStatusComponent } from "./blocks/watch-status/watch-status.component";
import { HomeComponent } from "./blocks/home/home.component";
import { NgbdModalComponentModule } from "./blocks/modal/modal-component.module";
import { GraphsComponent } from "./blocks/graphs/graphs.component";
import { AuthComponent } from "./auth/auth.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { LoaderComponent } from "./ui/loader/loader.component";
import { SamplingRatesComponent } from './sampling-rates/sampling-rates.component';
import { MatCheckboxModule, MatToolbarModule, MatSidenavModule, MatButtonModule, MatIconModule, MatDividerModule, MatTableModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input"
// import { SpinnerComponent } from './ui/spinner/spinner.component';
// import { ChartComponent } from './blocks/charts/charts.component';
// import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    GraphsComponent,
    AppComponent,
    DiscretePromptComponent,
    NumericPromptComponent,
    CognitiveComponent,
    WatchComponent,
    ParticipantComponent,
    HomeComponent,
    NavbarComponent,
    ConfigureWatchComponent,
    EditCampaignComponent,
    FeatureComponent,
    WatchStatusComponent,
    AuthComponent,
    SignUpComponent,
    LoaderComponent,
    SamplingRatesComponent
    // SpinnerComponent
    // GraphsComponent
    // ChartComponent
    // NgbdModalComponentModule
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AmplifyUIAngularModule,
    NgbModule,
    NgbdModalComponentModule,
    HttpClientModule,
    FormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatPaginatorModule,
    MatGridListModule,
    MatSortModule,
    MatCheckboxModule,
    RouterModule.forRoot([
      // { path: "", component: HomeComponent },
      // { path: "Participant", component: ParticipantComponent },
      // { path: "ConfigureWatch", component: ConfigureWatchComponent },
      // { path: "WatchStatus", component: WatchStatusComponent },
      // { path: "auth", component: AuthComponent }
    ]),
    BrowserAnimationsModule
  ],
  
  providers: [NgbActiveModal, FormControlDirective, FormGroupDirective],
  bootstrap: [AppComponent]
})
export class AppModule {}
