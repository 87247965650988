import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";

@Component({
  selector: "app-discrete-prompt",
  templateUrl: "./discrete-prompt.component.html",
})
export class DiscretePromptComponent implements OnInit {
  @Input() disableInput: boolean = false;
  discreteForm: FormGroup;
  items: any = [];
  count: number = 1;

  constructor(private fb: FormBuilder) {}
  ngOnInit() {
    var items = localStorage.getItem("discrete_form");
    if (items) {
      this.items = JSON.parse(items);
      // this.populateDiscrete(this.items);
    }

    this.discreteForm = this.fb.group({
      discretePrompts: this.fb.array([]),
    });

    const discretePrompt = this.fb.group({
      question: [""],
      question2: [""],
      type: [{ value: "discrete", disabled: true }],
      longUIquestion: [""],
      discrete_type: [true],
      values: this.fb.array([this.valuesArr()]),
      Prompts_time_0: this.fb.array([this.timeArr()]),
      Prompts_time_1: this.fb.array([this.timeArr()]),
      Prompts_time_2: this.fb.array([this.timeArr()]),
      Prompts_time_3: this.fb.array([this.timeArr()]),
      Prompts_time_4: this.fb.array([this.timeArr()]),
      Prompts_time_5: this.fb.array([this.timeArr()]),
      Prompts_time_6: this.fb.array([this.timeArr()])
    });
    if (items) {
      for (var i = 0; i < this.items.length; i++) {
        var temp = [];
        for (var j = 0; j < this.items[i].values.length; j++) {
          temp.push(
            this.fb.group({
              value: this.items[i].values[j],
            })
          );
        }
        const discretePromptItem = this.fb.group({
          question: [this.items[i].question],
          question2: [this.items[i].question2],
          type: [{ value: "discrete", disabled: true }],
          longUIquestion: [this.items[i].longUIquestion],
          discrete_type: [this.items[i].discrete_type],
          // values: this.fb.array([this.valuesArr()])
          values: this.fb.array(temp),
          Prompts_time_0: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_0)),
          Prompts_time_1: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_1)),
          Prompts_time_2: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_2)),
          Prompts_time_3: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_3)),
          Prompts_time_4: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_4)),
          Prompts_time_5: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_5)),
          Prompts_time_6: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_6))
        });
        // console.log(items);
        // discretePromptItem.controls.values.setValue(this.fb.array(temp));
        this.discretePromptForms.push(discretePromptItem);
      }
    } else {
      this.discretePromptForms.push(discretePrompt);
    }
    if (this.disableInput == true) {
      this.discreteForm.disable();
    }
    this.submit();
  }

  get discretePromptForms() {
    return this.discreteForm.get("discretePrompts") as FormArray;
  }

  valuesArr(): FormGroup {
    return this.fb.group({
      value: [""],
    });
  }

  addValue(i) {
    const valueArray = this.discreteForm
      .get("discretePrompts")
      ["controls"][i].get("values") as FormArray;
    valueArray.push(this.valuesArr());
  }
  deleteValue(i, iy) {
    const valueArray = this.discreteForm
      .get("discretePrompts")
      ["controls"][i].get("values") as FormArray;
    valueArray.removeAt(iy);
  }
  // deleteValue(values) {
  //   console.log(values);
  //   // values.removeAt(i);
  // }
  timeArr(): FormGroup {
        return this.fb.group({
          time: [{ hour: 3, minute: 10 }],
        });
    }
  constructTimeArray(promptTimes) {
    var timeTempArr = [];
    if (promptTimes) {
      for(var i=0;i<promptTimes.length;i++) {
        var timeJson = {hour: promptTimes[i]['time']['hour'], minute: promptTimes[i]['time']['minute']};
        timeTempArr.push(this.fb.group({
          time: timeJson,
        }));
      }
    }
    return timeTempArr;
  }
    addTimeValue(i, day) {
          var day_times_array = "Prompts_time_"+day;
          const timeArray = this.discreteForm
            .get("discretePrompts")
            ["controls"][i].get(day_times_array) as FormArray;
          timeArray.push(this.timeArr());
        }
        deleteTimeValue(i, iy, day) {
          var day_times_array = "Prompts_time_"+day;
          const timeArray = this.discreteForm
            .get("discretePrompts")
            ["controls"][i].get(day_times_array) as FormArray;
          timeArray.removeAt(iy);
        }
  addDiscretePrompt() {
    const discretePrompt = this.fb.group({
      question: [""],
      question2: [""],
      type: [{ value: "discrete", disabled: true }],
      longUIquestion: [""],
      discrete_type: [true],
      time: [""],
      values: this.fb.array([this.valuesArr()]),
      Prompts_time_0: this.fb.array([this.timeArr()]),
      Prompts_time_1: this.fb.array([this.timeArr()]),
      Prompts_time_2: this.fb.array([this.timeArr()]),
      Prompts_time_3: this.fb.array([this.timeArr()]),
      Prompts_time_4: this.fb.array([this.timeArr()]),
      Prompts_time_5: this.fb.array([this.timeArr()]),
      Prompts_time_6: this.fb.array([this.timeArr()])
    });

    this.discretePromptForms.push(discretePrompt);
  }
  deleteDiscretePrompt(i) {
    this.discretePromptForms.removeAt(i);
  }

  @Output() messageEvent = new EventEmitter<any>();

  submit() {
    localStorage.setItem(
      "discrete_form",
      JSON.stringify(this.discretePromptForms.value)
    );
    this.messageEvent.emit(this.discretePromptForms.value);
  }
}
