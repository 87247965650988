import { Component, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth/auth.service";
import { onAuthUIStateChange, CognitoUserInterface, AuthState, FormFieldTypes } from '@aws-amplify/ui-components';
import { Auth, Hub } from "aws-amplify";
import { Router } from "@angular/router";
import { thresholdFreedmanDiaconis } from "d3";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent{
  title = "ROAMM Web Portal";
  
  user: CognitoUserInterface | undefined;
  authState!: AuthState;
  formFields: FormFieldTypes;

  constructor(private authService: AuthService, private router: Router, private ref: ChangeDetectorRef, private ngZone: NgZone) {
    this.formFields = [
      { type: "username" },
      { type: "password" },
      { type: "email" }
    ];
  }

  ngOnInit() {
    Auth.currentAuthenticatedUser().then(user => {
      console.log(user);
        if (!!user) {
          this.router.navigate([""]);
          return;
        }
    });
    this.router.navigate(["/auth"]);
    
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      // if (this.authState == AuthState.SignedIn && this.user && this.router.url == "/auth") {
      //   this.router.navigate([""]);
      //   console.log("router url = " + this.router.url);
      // }
      this.ref.detectChanges();
    });

    Hub.listen('auth', (data) => {
      console.log(data.payload.event);
      switch (data.payload.event) {
        case 'signIn':
            console.log('user signed in');
            this.ngZone.run(()=> this.router.navigate([""]));
            break;
      }
    });
    
  }

  // ngOnDestroy() {
  //   return onAuthUIStateChange;
  // }

  // constructor(private authService: AuthService) {}
  // ngOnInit() {
  //   this.authService.autoLogin();
  // }
  // readonly ROOT_URL = "";
  // constructor(private http: HttpClient) {}

  // discretePrompt: any;
  // numericPrompt: any;
  // cognitive: any;
  // watch: any;

  // receiveDiscretePrompt($event) {
  //   this.discretePrompt = $event;
  // }

  // receiveNumericPrompt($event) {
  //   this.numericPrompt = $event;
  // }

  // receiveCognitive($event) {
  //   this.cognitive = $event;
  // }

  // receiveWatch($event) {
  //   this.watch = $event;
  // }

  // // submit() {
  // //   console.log(
  // //     this.discretePrompt,
  // //     this.numericPrompt,
  // //     this.cognitive,
  // //     this.watch
  // //   );

  // postData() {
  //   const data = {
  //     discretePrompt: this.discretePrompt,
  //     numericPrompt: this.numericPrompt,
  //     cognitive: this.cognitive,
  //     watch: this.watch
  //   };
  //   console.log(data);
  //   // this.http.post(this.ROOT_URL, data);
  // }
}
// }
