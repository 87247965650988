import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
@Component({
  selector: "app-numeric-prompt",
  templateUrl: "./numeric-prompt.component.html",
})
export class NumericPromptComponent implements OnInit {
  @Input() disableInput: boolean = false;
  numericForm: FormGroup;
  items: any = [];
  count: number = 1;

  constructor(private fb: FormBuilder) {}
  ngOnInit() {
    var items = localStorage.getItem("numeric_form");
    if (items) {
      this.items = JSON.parse(items);
      // this.populateDiscrete(this.items);
    }
    //console.log(items);
    this.numericForm = this.fb.group({
      numericPrompts: this.fb.array([]),
    });

    const numericPrompt = this.fb.group({
      question: [""],
      question2: [""],
      type: [{ value: "range", disabled: true }],
      longUIquestion: [""],
      min: [""],
      max: [""],
      inc: [""],
      default: [""],
      anchors: this.fb.array([this.anchorsArr()]),
      Prompts_time_0: this.fb.array([this.timeArr()]),
      Prompts_time_1: this.fb.array([this.timeArr()]),
      Prompts_time_2: this.fb.array([this.timeArr()]),
      Prompts_time_3: this.fb.array([this.timeArr()]),
      Prompts_time_4: this.fb.array([this.timeArr()]),
      Prompts_time_5: this.fb.array([this.timeArr()]),
      Prompts_time_6: this.fb.array([this.timeArr()])
      // time: []
    });
    if (items) {
      for (var i = 0; i < this.items.length; i++) {
        var temp = [];
            for (var j = 0; j < this.items[i].anchors.length; j++) {
              //console.log(this.items[i].anchors[j]);
              temp.push(
                this.fb.group({
                  from: [this.items[i].anchors[j].from],
                  to: [this.items[i].anchors[j].to],
                  value: [this.items[i].anchors[j].value],
                })
              );
        }
            //console.log(temp);
            //console.log(this.items[i].Prompts_time_0);
        const numericPromptItem = this.fb.group({
          question: [this.items[i].question],
          question2: [this.items[i].question2],
          type: [{ value: "range", disabled: true }],
          longUIquestion: [this.items[i].longUIquestion],
          min: [this.items[i].min],
          max: [this.items[i].max],
          inc: [this.items[i].inc],
          default: [this.items[i].default],
          anchors: this.fb.array(temp),
          Prompts_time_0: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_0)),
          Prompts_time_1: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_1)),
          Prompts_time_2: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_2)),
          Prompts_time_3: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_3)),
          Prompts_time_4: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_4)),
          Prompts_time_5: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_5)),
          Prompts_time_6: this.fb.array(this.constructTimeArray(this.items[i].Prompts_time_6))
        });
        // discretePromptItem.controls.values.setValue(this.fb.array(temp));
        this.numericPromptForms.push(numericPromptItem);
        //console.log(this.numericPromptForms);
      }
    } else {
      console.log(this.numericPromptForms);
      this.numericPromptForms.push(numericPrompt);
    }
    if (this.disableInput == true) {
      this.numericForm.disable();
    }
    this.submit();
  }
  get numericPromptForms() {
    return this.numericForm.get("numericPrompts") as FormArray;
  }
  timeArr(): FormGroup {
      return this.fb.group({
        time: [{ hour: 3, minute: 10, second: 0 }],
      });
  }
  constructTimeArray(promptTimes) {
    var timeTempArr = [];
    if (promptTimes) {
      for(var i=0;i<promptTimes.length;i++) {
        var timeJson = {hour: promptTimes[i]['time']['hour'], minute: promptTimes[i]['time']['minute']};
        timeTempArr.push(this.fb.group({
          time: timeJson,
        }));
      }
    }
    return timeTempArr;
  }
  addValue(i, day) {
      var day_times_array = "Prompts_time_"+day;
      const timeArray = this.numericForm
        .get("numericPrompts")
        ["controls"][i].get(day_times_array) as FormArray;
      console.log(this.numericForm);
      timeArray.push(this.timeArr());
    }
    deleteValue(i, iy, day) {
      var day_times_array = "Prompts_time_"+day;
      const timeArray = this.numericForm
        .get("numericPrompts")
        ["controls"][i].get(day_times_array) as FormArray;
      timeArray.removeAt(iy);
    }
  addNumericPrompt() {
    const numericPrompt = this.fb.group({
      question: [""],
      question2: [""],
      type: [{ value: "range", disabled: true }],
      longUIquestion: [""],
      min: [""],
      max: [""],
      inc: [""],
      default: [""],
      time: [""],
      anchors: this.fb.array([this.anchorsArr()]),
      Prompts_time_0: this.fb.array([this.timeArr()]),
      Prompts_time_1: this.fb.array([this.timeArr()]),
      Prompts_time_2: this.fb.array([this.timeArr()]),
      Prompts_time_3: this.fb.array([this.timeArr()]),
      Prompts_time_4: this.fb.array([this.timeArr()]),
      Prompts_time_5: this.fb.array([this.timeArr()]),
      Prompts_time_6: this.fb.array([this.timeArr()])
    });
    this.numericPromptForms.push(numericPrompt);
  }

  deleteNumericPrompt(i) {
    this.numericPromptForms.removeAt(i);
  }
  anchorsArr(): FormGroup {
      return this.fb.group({
        from: [1],
        to: [2],
        value: ["range"]
      });
    }

    addAnchor(i) {
      const valueArray = this.numericForm
        .get("numericPrompts")
        ["controls"][i].get("anchors") as FormArray;
      valueArray.push(this.anchorsArr());
    }
    deleteAnchor(i, iy) {
      const valueArray = this.numericForm
        .get("numericPrompts")
        ["controls"][i].get("anchors") as FormArray;
      valueArray.removeAt(iy);
    }
  onsubmit() {
    console.log(this.numericPromptForms);
  }
  isNumeric(val): boolean {
    //console.log("in numeric");
    return typeof val === 'number';
  }

  @Output() messageEvent = new EventEmitter<any>();

  submit() {
    localStorage.setItem(
      "numeric_form",
      JSON.stringify(this.numericPromptForms.value)
    );
    this.messageEvent.emit(this.numericPromptForms.value);
  }
}
